import React, { useEffect, useState } from 'react';
import { Alert, Box, AlertTitle, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { Auth } from 'aws-amplify';
import SubscribeToAnalyser from '../Components/SubscribeToAnalyser';

const AnalyserProtectedRoute = ({ component: Component }) => {
    const [hasAnalyser, setHasAnalyser] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAnalyserAccess = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const userAttributes = await Auth.userAttributes(user);
                const analyserAttribute = userAttributes.find(attr => attr.Name === 'custom:hasAnalyser');
                setHasAnalyser(analyserAttribute && analyserAttribute.Value === 'true');
            } catch (error) {
                console.error('Error checking analyser access:', error);
            } finally {
                setLoading(false);
            }
        };

        checkAnalyserAccess();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!hasAnalyser) {
        return (
            <Box sx={{ margin: 4 }}>

                <Box sx={{ mb: 2 }}>
                    <Alert severity="error" icon={<BlockIcon sx={{ fontSize: '3rem' }} />} sx={{ mb: 2, border: '1px solid #f44336' }}>
                        <AlertTitle>
                            <Typography variant='h5' align='left'>
                                Access Restricted
                            </Typography>
                        </AlertTitle>
                        <Typography variant='body1' align='left'>
                            This feature is only available to Exam Display customers with an Analyser add-on subscription.
                        </Typography>
                        <Typography variant='body1' align='left'>
                            Click the button below to subscribe to the Analyser feature.
                        </Typography>
                    </Alert>
                    <SubscribeToAnalyser color="primary" fullWidth={true} />
                </Box>

                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                        src="https://player.vimeo.com/video/976549133?badge=0&autopause=0&player_id=0&app_id=58479"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        title="Exam Analyser by Exam Display"
                    />
                </div>
            </Box>
        );
    }

    return <Component />;
};

export default AnalyserProtectedRoute;