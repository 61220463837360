// src/utils/cognitoUtils.js

import { Auth } from 'aws-amplify';

export const updateAnalyserAccess = async (cognitoUserId) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const subAttribute = userAttributes.find(attr => attr.Name === 'sub');

        if (subAttribute?.Value !== cognitoUserId) {
            throw new Error('Cognito User ID mismatch');
        }

        await Auth.updateUserAttributes(user, {
            'custom:hasAnalyser': 'true'
        });

        console.log('User attribute updated successfully');
        return true;
    } catch (error) {
        console.error('Error updating user attribute:', error);
        return false;
    }
};