import React, { useEffect, useState } from 'react';
import { initializePaddle } from '@paddle/paddle-js';

const SubscribePaddlePage = () => {
    const [paddle, setPaddle] = useState(null);

    useEffect(() => {
        initializePaddle({
            environment: 'production',
            token: 'live_9886c38b44ddb42ec3466cb3bd2',
        })
            .then((paddleInstance) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                } else {
                    console.error('Paddle initialization failed');
                }
            })
            .catch((error) => {
                console.error('Error initializing Paddle:', error);
            });
    }, []);

    return (
        <div>Paddle page</div>
    );
};

export default SubscribePaddlePage;
