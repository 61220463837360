import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Grid } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export default function Subscribe(props) {

    const handleClick = async e => {
        const stripe = await loadStripe('pk_live_51KagjnH3FcHVT73wfc4zn7gzA1O07DeXwwwPNaH8JxkebxRpNvW1wNhpR9QoQhGt2vzcOq7PyJJa7VA6CTxEKevu00Chv0BHCQ')
        const { error } = await stripe.redirectToCheckout({
            mode: 'subscription',
            lineItems: [{
                price: 'price_1MckzaH3FcHVT73wo7RuLEhf', //OLD: price_1KuHk9H3FcHVT73wbbYnXaKl
                quantity: 1
            }],
            successUrl: 'https://www.examdisplay.com/success',
            cancelUrl: 'https://www.examdisplay.com/'
        })
    }

    return (
        <Grid container>
            <Button
                variant='contained'
                key="signIn"
                onClick={handleClick}
                color={props.color}
                startIcon={<AddBoxOutlinedIcon />}
                fullWidth
                sx={{ color: '#ffffff' }}
            > Create account
            </Button>
        </Grid>
    )
}