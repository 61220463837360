// External Libraries
import { atom, useAtom } from 'jotai';
import { Auth } from 'aws-amplify';

const loggedInAtom = atom(false);

const useLoggedIn = () => {
    const [loggedIn, setLoggedIn] = useAtom(loggedInAtom);

    const AssessLoggedInState = () => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                setLoggedIn(true);
            })
            .catch(() => {
                setLoggedIn(false);
            });
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            setLoggedIn(false);
        } catch (error) {
            console.log('error signing out', error);
        }
    };

    const onSignIn = () => {
        setLoggedIn(true);
    };

    return {
        loggedIn,
        AssessLoggedInState,
        signOut,
        onSignIn,
    };
};

export default useLoggedIn;