import { Auth } from 'aws-amplify';

export const loadCustomAttributes = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = await Auth.userAttributes(user);
        const customAttributes = {};

        userAttributes.forEach((attr) => {
            if (attr.Name.startsWith('custom:')) {
                const attributeName = attr.Name.replace('custom:', '');
                customAttributes[attributeName] = attr.Value;
            }
        });

        return customAttributes;
    } catch (error) {
        console.error('Error loading custom attributes:', error);
        return {};
    }
};

export const saveCustomAttributes = async (attributes) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = {};

        for (const [key, value] of Object.entries(attributes)) {
            userAttributes[`custom:${key}`] = value;
        }

        await Auth.updateUserAttributes(user, userAttributes);
    } catch (error) {
        console.error('Error saving custom attributes:', error);
    }
};