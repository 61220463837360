import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Grid, Link, Typography } from '@mui/material';

export default function Footer() {
  return (

    <Container maxWidth="lg">
    <Grid 
      container 
      marginTop="1.5rem"
      direction="row"
      justifyContent='space-between'
      alignItems='start'
      display='flex'
      >

      <Grid item xs={4}>
        <Typography fontWeight={600} align='center' color='text.disabled'>
          Legal
        </Typography>
        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Aup`} color="primary">
            Acceptable Use
          </Link>
        </Typography>

        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Cookies`} color="primary">
            Cookies
          </Link>
        </ Typography>

        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Privacy`} color="primary">
            Privacy
          </Link>
        </Typography>

        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Terms`} color="primary">
            Terms
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography fontWeight={600} align='center' color='text.disabled'>
            Learn more
        </Typography>
        <Typography variant='body2' align='center'>
          <Link underline="none" href='https://docs.examdisplay.com' color="primary" target="_blank" rel="noopener">
            Documentation
          </Link>
        </Typography>

        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Support`} color="primary">
            Support
          </Link>
        </Typography>

      </Grid>

      <Grid item xs={4}>
        <Typography fontWeight={600} align='center' color='text.disabled'>
            Company
        </Typography>
        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/About`} color="primary">
            About
          </Link>
        </Typography>
        <Typography variant='body2' align='center'>
          <Link underline="none" component={RouterLink} to={`/Contact`} color="primary">
            Contact
          </Link>
        </Typography>
      </Grid>
      

      <Grid item xs={12} mt={1} mb={2}>
        <Typography variant='caption'>
          Copyright © 2022-{new Date().getFullYear()} - Lompla Ltd
        </Typography>
      </Grid>
    </Grid>
    </Container>
  );
}