// External Libraries
import * as React from 'react';

// Local Components
import Features from '../Components/Home/Features';
import Footer from '../Components/Footer';
import Hero from '../Components/Home/Hero';

export default function Home() {
  return (
    <React.Fragment>

      <Hero />

      <Features />

      <Footer />

    </React.Fragment>
  );
}