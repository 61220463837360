import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Link as RouterLink } from 'react-router-dom';

const AnalyserCancelPage = () => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CancelOutlinedIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
                <Typography component="h1" variant="h4" gutterBottom>
                    Subscription Cancelled
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    Your Analyser feature subscription process was cancelled. No charges have been made to your account.
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    If you encountered any issues or have changed your mind, you can try subscribing again or return to the main page.
                </Typography>
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to="/subscribe-analyser"
                        >
                            Try Again
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            component={RouterLink}
                            to="/"
                        >
                            Return to Home
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default AnalyserCancelPage;