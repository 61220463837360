// External Libraries
import React from 'react';
import { DialogTitle, Grid, Stack, Chip, Box, Typography, IconButton } from '@mui/material';

// Icons
import { Help } from '@mui/icons-material';

const CustomDialogTitle = ({ title, url, beta }) => {

    const handleClick = () => {
        const rootUrl = 'https://docs.examdisplay.com/';
        window.open(`${rootUrl}${url}`, '_blank');
    };

    return (
        <DialogTitle sx={{ fontSize: '1.5rem' }}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    {beta && <Chip label="Beta" variant="Filled" color='warning' size='small' />}
                    <Box display="flex" alignItems="flex-start">
                        <Typography variant='h5' align='left'>{title}</Typography>
                        <IconButton onClick={handleClick} sx={{ mt: -1.5 }}>
                            <Help sx={{ fontSize: 20 }} color='info' />
                        </IconButton>
                    </Box>
                </Stack>
            </Grid>
        </DialogTitle>
    );
};

export default CustomDialogTitle;
