import React from 'react';
import { Alert, Button, Box, AlertTitle, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BlockIcon from '@mui/icons-material/Block';

const ProtectedRoute = ({ isBeta, component: Component }) => {
    if (!isBeta) {
        return (
            <Box sx={{ margin: 4 }}>
                <Alert severity="error" icon={<BlockIcon sx={{ fontSize: '3rem' }} />} sx={{ mb: 2, border: '1px solid #f44336' }}>
                    <AlertTitle>
                        <Typography variant='h5' align='left'>
                            Access Restricted
                        </Typography>
                    </AlertTitle>
                    <Typography variant='body1' align='left'>
                        This feature is currently available only to users in our Beta Programme.
                    </Typography>
                    <Typography variant='body1' align='left'>
                        Email contact@lompla.com if you want to join the programme.
                    </Typography>
                    <Typography variant='body1' align='left'>
                        If you are part of the programme and cannot access this page, please email contact@lompla.com.
                    </Typography>
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<MailOutlineIcon />}
                    onClick={() => {
                        // Ideally, this should open the user's default mail client with pre-filled details
                        window.location.href = `mailto:contact@lompla.com?subject=Count me in for beta&body=Hi, I would like to join the Beta Programme.`;
                    }}
                >
                    Join Beta Programme
                </Button>
            </Box>
        );
    }

    return <Component />;
};

export default ProtectedRoute;