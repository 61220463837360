import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { loadCustomAttributes, saveCustomAttributes } from '../../Helpers/cognitoAttributes';

export const defaultColors = [
    "#37517e",
    "#008fc9",
    "#2ead4b",
    "#c97500",
    "#cf2929"
];

export const colorFavsAtom = atomWithStorage('colorFavs', []);

export const colorsAtom = atom((get) => {
    const colorFavs = get(colorFavsAtom);
    return [...defaultColors, ...colorFavs];
});

export const loadColorFavs = atom(null, async (get, set) => {
    const customAttributes = await loadCustomAttributes();
    const colorFavs = JSON.parse(customAttributes.colorFavs || '[]');
    if (Array.isArray(colorFavs)) {
        set(colorFavsAtom, colorFavs);
    }
});

export const saveColorFavs = atom(null, async (get, set) => {
    const colorFavs = get(colorFavsAtom);
    await saveCustomAttributes({ colorFavs: JSON.stringify(colorFavs) });
});