// External Libraries
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';

// Icons
import {
  AccountBalanceOutlined, AutoFixHighOutlined, CalculateOutlined, CurrencyPoundOutlined, DynamicFeed,
  HourglassBottomOutlined, ImportantDevicesOutlined, MoreTimeOutlined,
  NotificationsActiveOutlined, QuizOutlined, SentimentSatisfiedOutlined, SettingsSuggestOutlined,
  Speed, WifiOff
} from '@mui/icons-material';

// Local components
import Subscribe from '../Subscribe';

// Hooks
import useLoggedIn from '../../hooks/useLoggedIn';

export default function Features() {

  const { loggedIn } = useLoggedIn();

  const cards = [
    {
      icon: <Typography variant='h3' color='tertiary.main'>2000+</Typography>,
      header: 'Examinations',
      text: 'Database with details of over 2,000 exam papers pre-loaded with duration, dates and names',
    },
    {
      icon: <QuizOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Leading qualifications',
      text: 'Leading qualifications fully supported, including IB DP, AP®, GCSE, IGCSE, AS/Level, International A Level',
    },
    {
      icon: <AccountBalanceOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Leading exam boards',
      text: 'All main exam boards supported, including IBO, CAIE, Pearson, AQA, OCR, College Board® (no affiliation with any board)',
    },
    {
      icon: <CalculateOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Start/Finish times',
      text: 'Display the correct start/finish times for all your exams, in a few clicks. Get it right. Every time',
    },
    {
      icon: <HourglassBottomOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Countdowns',
      text: 'Multiple countdowns for all your exams, including extra time. Students always know exactly how long they have left',
    },
    {
      icon: <MoreTimeOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Extra time',
      text: 'In one click, calculate and display extra time for all your exam papers (e.g. +25%, +50%), in real time. Including custom extra time.',
    },
    {
      icon: <NotificationsActiveOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Reminders',
      text: 'Automatically calculate and display remaining time reminders (5 minutes, 30 minutes)',
    },
    {
      icon: <DynamicFeed sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Multiple exams',
      text: 'Multiple exam papers in one invigilation room (multiple exam boards - no limits!)',
    },
    {
      icon: <AutoFixHighOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Fully editable',
      text: 'Edit duration, paper and subject names. Great for mock exams! ',
    },
    {
      icon: <WifiOff sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Works offline',
      text: 'Spotty Internet connection? ExamDisplay works even offline!',
    },
    {
      icon: <CurrencyPoundOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Great value',
      text: 'Yearly subscription for £25. Sign up for an account now, and create displays instantly',
    },
    {
      icon: <SentimentSatisfiedOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Easy to use',
      text: 'Created with and for invigilators. Train your invigilators in minutes.',
    },
    {
      icon: <SettingsSuggestOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Customisable',
      text: 'Zoom in/out, show/hide parts of your invigilation screens, choose colourful or high-contrast displays. The choice is yours ',
    },
    {
      icon: <ImportantDevicesOutlined sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Works on all devices',
      text: 'ExamDisplay works on all types of devices that support modern Web browsers, including iPad and Chromebook',
    },
    {
      icon: <Speed sx={{ fontSize: '50px', color: 'tertiary.main' }} />,
      header: 'Built for speed',
      text: 'Built on the world\'s leading cloud infrastructure, enjoy a fast and smooth experience even during peak times',
    },

  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          pt: 2,
          pb: 3,
          mb: 3,
        }}
      >

        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            fontSize: {
              lg: '4rem',
              md: '3rem',
              sm: '2rem',
              xs: '1.5rem'
            }
          }}
        >
          Features
        </Typography>

        <Container maxWidth="lg" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {cards.map((card) => (
              <Grid item key={card.header} xs={12} sm={12} md={6} lg={4} height='20rem'>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardHeader
                    title={card.icon}
                  />
                  <CardContent sx={{ flexGrow: 1, alignItems: 'center' }}>
                    <Typography variant='h5' mb={3} sx={{ fontWeight: 900 }}>{card.header}</Typography>
                    <Typography variant='body'>{card.text}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {!loggedIn ?
            <Grid item xs={9} mt={4}>
              <Subscribe color='success' />
            </Grid>
            :
            ''
          }
        </Container>
      </Box>
    </React.Fragment>
  );
}