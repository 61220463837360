import { atomWithStorage } from 'jotai/utils';

export const todayAtom = atomWithStorage('today', null);
export const numCardsAtom = atomWithStorage('numCards', 0);
export const startTimeAtom = atomWithStorage('startTime', null);
export const handleAtom = atomWithStorage('handle', null);
export const fullcssAtom = atomWithStorage('fullcss', false);
export const readyAtom = atomWithStorage('ready', false);
export const startReadingAtom = atomWithStorage('startReading', false);
export const startReadingTimeAtom = atomWithStorage('startReadingTime', null);
export const startExamAtom = atomWithStorage('startExam', false);
export const readingTimeValueAtom = atomWithStorage('readingTimeValue', 5);