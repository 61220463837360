// useFullScreen.js
import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';
import { fullcssAtom } from '../Jotai/Atoms/examAtoms';

const useFullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useAtom(fullcssAtom);

    const enterFullScreen = useCallback(() => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        }
    }, []);

    const exitFullScreen = useCallback(() => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }, []);

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(document.fullscreenElement !== null);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, [setIsFullScreen]);

    return { isFullScreen, enterFullScreen, exitFullScreen };
};

export default useFullScreen;