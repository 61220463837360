import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { Auth } from 'aws-amplify';
import { initializePaddle } from '@paddle/paddle-js';
import { updateAnalyserAccess } from '../Utils/cognitoUtils';

const SubscribeToAnalyser = ({ color = 'primary', fullWidth = false }) => {
    const [paddle, setPaddle] = useState(null);
    // console.log('Paddle:', paddle);

    useEffect(() => {
        // console.log('Initializing Paddle...');
        initializePaddle({
            environment: 'production',
            token: 'live_9886c38b44ddb42ec3466cb3bd2',
            eventCallback: async function (data) {
                // console.log('Paddle event:', data);
                if (data.name === "checkout.completed") {
                    // console.log('Checkout completed:', data);
                    const user = await Auth.currentAuthenticatedUser();
                    const cognitoUserId = user.attributes.sub;
                    const updateSuccess = await updateAnalyserAccess(cognitoUserId);
                    if (updateSuccess) {
                        window.location.href = `${window.location.origin}/analyser-success`;
                    } else {
                        console.error('Failed to update user attribute.');
                    }
                } else if (data.name === "checkout.closed") {
                    // console.log('Checkout closed');
                } else {
                    // console.log('Paddle event:', data);
                }
            }
        })
            .then((paddleInstance) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                    // console.log('Paddle initialized successfully:', paddleInstance);
                } else {
                    console.error('Paddle initialization failed');
                }
            })
            .catch((error) => {
                console.error('Error initializing Paddle:', error);
            });
    }, []);

    const handleSubscribe = async () => {
        if (!paddle) {
            console.error('Paddle instance not available');
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            // console.log('Authenticated user:', user);
            const email = user.attributes.email;

            // console.log('Opening Paddle checkout...');
            paddle.Checkout.open({
                items: [{ priceId: 'pri_01j1wme6spvnttfwbpdzp7sgrt', quantity: 1 }],
                email: email
            });
        } catch (error) {
            console.error('Error opening Paddle checkout:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            }
        }
    };

    return (
        <Button
            variant='contained'
            onClick={handleSubscribe}
            color={color}
            startIcon={<ShoppingCart />}
            fullWidth={fullWidth}
            sx={{ color: '#ffffff' }}
        >
            Subscribe to Analyser
        </Button>
    );
};

export default SubscribeToAnalyser;
