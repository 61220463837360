// External Libraries
import * as React from 'react';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// Icons
import { AddToQueueOutlined, Assessment, Login } from '@mui/icons-material';

// Local Components
import Subscribe from '../Subscribe';

// Hooks
import useLoggedIn from '../../hooks/useLoggedIn';

export default function Hero() {

  const { loggedIn } = useLoggedIn();

  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 2,
          pb: 3,
          mt: 2,
          mb: 3,
        }}
      >
        <Container maxWidth="lg" component="main">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{
              fontSize: {
                lg: '4rem',
                md: '3rem',
                sm: '2rem',
                xs: '1.5rem'
              }
            }}
          >
            Exam invigilation displays in<span style={{ fontWeight: 700, color: '#2ead4b' }}> seconds</span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: 800,
                height: 450,
                position: 'relative',
              }}
            >
              <Box
                component="div"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                dangerouslySetInnerHTML={{
                  __html: `<iframe src="https://player.vimeo.com/video/925371303?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Exam Display - Create stunning exam screens in seconds!"></iframe>`
                }}
              />
            </Box>
          </Box>
          {loggedIn ? (
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              sx={{ lineHeight: '2rem', mt: 2 }}
              startIcon={<AddToQueueOutlined size="large" />}
            >
              <Link sx={{ textDecoration: "none", color: '#ffffff' }} component={RouterLink} to={`/invigilation`}>Create exam displays</Link>
            </Button>
          ) : (
            <>
              <Stack
                sx={{ pt: 4, mb: 1 }}
                spacing={3}
                direction="row"
                justifyContent="center"
              >
                <Subscribe color="success" />
                <Button
                  variant="contained"
                  fullWidth
                  color="info"
                  startIcon={<Assessment size="large" sx={{ color: "#ffffff" }} />}
                >
                  <Link sx={{ textDecoration: "none", color: "#ffffff" }} component={RouterLink} to={`/trial`}>Request trial</Link>
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  color="tertiary"
                  startIcon={<Login size="large" sx={{ color: "#ffffff" }} />}
                >
                  <Link sx={{ textDecoration: "none", color: "#ffffff" }} component={RouterLink} to={`/signin`}>Sign in</Link>
                </Button>
              </Stack>
              <Typography
                variant="caption"
                align="left"
                color="secondary"
              >
                Need a different payment method, or purchase order? <Link sx={{ textDecoration: "none", color: "tertiary.main" }} component={RouterLink} to={`/contact`}>Contact us</Link>
              </Typography>
            </>
          )}
        </Container>
      </Box>
    </React.Fragment>
  );
}