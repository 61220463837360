import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link as RouterLink } from 'react-router-dom';

const AnalyserSuccessPage = () => {
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CheckCircleOutlineIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
                <Typography component="h1" variant="h4" gutterBottom>
                    Subscription Successful!
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    Thank you for subscribing to the Analyser feature. Your account has been successfully upgraded, and you now have access to all Analyser capabilities.
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    You can start using the Analyser feature right away. If you have any questions or need assistance, please don't hesitate to contact our support team.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={RouterLink}
                    to="/analyser"
                    sx={{ mt: 3 }}
                >
                    Go to Analyser
                </Button>
            </Box>
        </Container>
    );
};

export default AnalyserSuccessPage;