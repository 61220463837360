import React, { useContext } from "react";
import {IconButton, Tooltip } from "@mui/material";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import ColorModeContext from "./ColorModeContext";

export const ThemeToggleButton = React.forwardRef((props, ref) => {
  const { colorMode, toggleColorMode } = useContext(ColorModeContext);

  return (
    <Tooltip title='Toggle dark mode'>
      <IconButton ref={ref} onClick={toggleColorMode} color={props.color}>
        {colorMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
    </Tooltip>
  );
})