import { atom, useAtom } from 'jotai';
import { Auth } from 'aws-amplify';

// Define atoms for each user attribute
const isBetaAtom = atom(false);
const localeAtom = atom('en-gb');
const zoneInfoAtom = atom('');
const ibAdminCodeAtom = atom('');
const ibTimeOffsetAtom = atom('');
const hasAnalyserAtom = atom(false);

const useUserData = () => {
    const [isBeta, setIsBeta] = useAtom(isBetaAtom);
    const [locale, setLocale] = useAtom(localeAtom);
    const [zoneInfo, setZoneInfo] = useAtom(zoneInfoAtom);
    const [ibAdminCode, setIbAdminCode] = useAtom(ibAdminCodeAtom);
    const [ibTimeOffset, setIbTimeOffset] = useAtom(ibTimeOffsetAtom);
    const [hasAnalyser, setHasAnalyser] = useAtom(hasAnalyserAtom);

    const fetchUserData = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('User:', user);
            const { payload } = user.signInUserSession.accessToken;
            console.log('User data', payload);

            // Check if user is in beta group
            setIsBeta(payload['cognito:groups']?.includes('beta') || false);

            // Set locale, defaulting to 'en-gb' if not present
            setLocale(payload.locale || 'en-gb');

            // Set zoneInfo, defaulting to empty string if not present
            setZoneInfo(payload.zoneinfo || '');

            // Set IB admin code, using the correct custom attribute
            setIbAdminCode(payload['custom:ibAdminCode'] || '');

            // Set IB time offset, using the correct custom attribute
            setIbTimeOffset(payload['custom:ibTimeOffset'] || '');

            // Check if user has analyser access, using the correct custom attribute
            setHasAnalyser(payload['custom:hasAnalyser'] == 'true');

            console.log('User data fetched successfully');
        } catch (error) {
            console.error('Error fetching user data:', error);
            resetUserData();
        }
    };

    const resetUserData = () => {
        setIsBeta(false);
        setLocale('en-gb');
        setZoneInfo('');
        setIbAdminCode('');
        setIbTimeOffset('');
        setHasAnalyser(false);
    };

    const checkAnalyserAccess = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const { payload } = user.signInUserSession.accessToken;
            return payload['custom:hasAnalyser'] == 'true';
        } catch (error) {
            console.error('Error checking analyser access:', error);
            return false;
        }
    };

    return {
        isBeta,
        locale,
        zoneInfo,
        ibAdminCode,
        ibTimeOffset,
        hasAnalyser,
        fetchUserData,
        checkAnalyserAccess
    };
};

export default useUserData;