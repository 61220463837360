// External Libraries
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Badge, Box, Button, Chip, Container, FormControlLabel, IconButton, Link, Menu, MenuItem, Switch, Toolbar, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai';

// Icons
import { AccountCircle, Add, DisplaySettings, DisplaySettingsOutlined, Help, Login, Logout, Menu as MenuIcon, Paid, Save, Settings } from '@mui/icons-material';

// Local components
import { ThemeToggleButton } from './ThemeToggleButton';

// Hooks
import useLocalStorage from '../hooks/useLocalStorage';
import useFullScreen from '../hooks/useFullScreen';
import { usePreferences } from '../hooks/usePreferences';
import { useInvigilationActions } from './Invigilation/hooks/useInvigilationActions';

// State
import { isBetaAtom } from '../Jotai/Atoms/userAtoms';

const NavBar = ({ loggedIn, signOut }) => {

  const [isBeta] = useAtom(isBetaAtom);

  const {
    setSelectedRows,
    setSelectionModel,
  } = useInvigilationActions();

  const { isFullScreen } = useFullScreen();

  const navItems = [
    { name: 'Home', route: 'Home' },
    { name: 'Timer', route: 'Timer' },
    { name: 'Display', route: 'Invigilation' },
    { name: 'Analyser', route: 'Analyser' },
    { name: 'Scheduler', route: 'Scheduler' },
  ];

  const [anchorElDisplay, setAnchorElDisplay] = React.useState(null);

  const handleDisplayMenuOpen = (event) => {
    setAnchorElDisplay(event.currentTarget);
  };

  const handleDisplayMenuClose = () => {
    setAnchorElDisplay(null);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(false);
  const [betaToggle, setBetaToggle] = useLocalStorage('betaToggle', false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseDisplayMenu = () => {
    setAnchorElDisplay(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleBetaToggle = () => {
    setBetaToggle(!betaToggle);
    window.location.reload();
  };

  const { handleSetShowSettingsPanel } = usePreferences();

  return (
    !isFullScreen && (
      <AppBar position="sticky" elevation={0} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container maxWidth="false">
          <Toolbar disableGutters color="white">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Link style={{ textDecoration: "none", color: "white" }} component={RouterLink} to={`/`}>
                <DisplaySettingsOutlined sx={{ fontSize: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navItems.map((item) => {
                  if (item.name === 'Display') {
                    return [
                      <Menu
                        key={`${item.name}-menu`}
                        anchorEl={anchorElDisplay}
                        open={Boolean(anchorElDisplay)}
                        onClose={handleDisplayMenuClose}
                      >
                        <MenuItem onClick={() => {
                          handleCloseDisplayMenu();
                          setSelectedRows([]);
                          setSelectionModel([]);
                        }}>
                          <Link
                            component={RouterLink}
                            to="/invigilation"
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                          >
                            <Add sx={{ mr: 1 }} />
                            New display
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={() => {
                          handleCloseDisplayMenu();
                        }}>
                          <Link
                            component={RouterLink}
                            to="/invigilation/saved"
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                          >
                            <Save sx={{ mr: 1 }} />
                            Saved displays
                          </Link>
                        </MenuItem>
                      </Menu>
                    ];
                  } else if (item.name === 'Scheduler' && isBeta) {
                    return (
                      <MenuItem key={item.name} onClick={handleCloseNavMenu}>
                        <Badge
                          badgeContent={
                            <Typography variant='body2' sx={{ fontSize: '0.85rem', color: '#ffffff' }}>
                              Beta
                            </Typography>
                          }
                          color="warning"
                          sx={{ '.MuiBadge-badge': { transform: 'scale(0.85) translate(40%, 80%)' } }}
                        >
                          <Typography textAlign="center">
                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to={`/${item.route}`}>
                              {item.name}
                            </Link>
                          </Typography>
                        </Badge>
                      </MenuItem>
                    );
                  } else if (item.name === 'Analyser') {
                    return (
                      <MenuItem key={item.name} onClick={handleCloseNavMenu}>
                        <Badge
                          badgeContent={
                            <Typography variant='body2' sx={{ fontSize: '0.85rem', color: '#ffffff' }}>
                              New
                            </Typography>
                          }
                          color="success"
                          sx={{ '.MuiBadge-badge': { transform: 'scale(0.85) translate(40%, 80%)' } }}
                        >
                          <Typography textAlign="center">
                            <Link style={{ textDecoration: 'none' }} component={RouterLink} to={`/${item.route}`}>
                              {item.name}
                            </Link>
                          </Typography>
                        </Badge>
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={item.name} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">
                          <Link style={{ textDecoration: 'none' }} component={RouterLink} to={`/${item.route}`}>
                            {item.name}
                          </Link>
                        </Typography>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {navItems.map((item) => {
                if (item.name === 'Display') {
                  return [
                    <Button
                      key={item.name}
                      onClick={handleDisplayMenuOpen}
                      variant="outlined"
                      sx={{ my: 2, mx: 0.5, color: 'white', borderColor: 'primary.light' }}
                    >
                      {item.name}
                    </Button>,
                    <Menu
                      key={`${item.name}-menu`}
                      anchorEl={anchorElDisplay}
                      open={Boolean(anchorElDisplay)}
                      onClose={handleDisplayMenuClose}
                    >
                      <MenuItem onClick={() => {
                        handleCloseDisplayMenu();
                        setSelectedRows([]);
                        setSelectionModel([]);
                      }}>
                        <Link
                          component={RouterLink}
                          to="/invigilation"
                          style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                        >
                          <Add sx={{ mr: 1 }} />
                          New display
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleCloseDisplayMenu}>
                        <Link
                          component={RouterLink}
                          to="/invigilation/saved"
                          style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                        >
                          <Save sx={{ mr: 1 }} />
                          Saved displays
                        </Link>
                      </MenuItem>
                    </Menu>
                  ];
                } else if (item.name === 'Scheduler' && isBeta) {
                  return (
                    <Badge
                      key={item.name}
                      badgeContent={
                        <Typography variant='body2' sx={{ fontSize: '0.85rem', color: '#ffffff' }}>
                          Beta
                        </Typography>
                      }
                      color="warning"
                      sx={{ '.MuiBadge-badge': { transform: 'scale(0.85) translate(40%, 80%)' } }}
                    >
                      <Button
                        onClick={handleCloseNavMenu}
                        variant="outlined"
                        sx={{ my: 2, mx: 0.5, color: 'white', borderColor: 'primary.light' }}
                      >
                        <Link style={{ textDecoration: 'none', color: 'white' }} component={RouterLink} to={`/${item.route}`}>
                          {item.name}
                        </Link>
                      </Button>
                    </Badge>
                  );
                } else if (item.name === 'Analyser') {
                  return (
                    <Badge
                      key={item.name}
                      badgeContent={
                        <Typography variant='body2' sx={{ fontSize: '0.85rem', color: '#ffffff' }}>
                          New
                        </Typography>
                      }
                      color="success"
                      sx={{ '.MuiBadge-badge': { transform: 'scale(0.85) translate(40%, 80%)' } }}
                    >
                      <Button
                        onClick={handleCloseNavMenu}
                        variant="outlined"
                        sx={{ my: 2, mx: 0.5, color: 'white', borderColor: 'primary.light' }}
                      >
                        <Link style={{ textDecoration: 'none', color: 'white' }} component={RouterLink} to={`/${item.route}`}>
                          {item.name}
                        </Link>
                      </Button>
                    </Badge>
                  );
                } else if (item.name !== 'Scheduler') {
                  return (
                    <Button
                      key={item.name}
                      onClick={handleCloseNavMenu}
                      variant="outlined"
                      sx={{ my: 2, mx: 0.5, color: 'white', borderColor: 'primary.light' }}
                    >
                      <Link style={{ textDecoration: 'none', color: 'white' }} component={RouterLink} to={`/${item.route}`}>
                        {item.name}
                      </Link>
                    </Button>
                  );
                }
                return null;
              })}
            </Box>


            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <DisplaySettingsOutlined fontSize="large" />
            </Typography>

            {loggedIn ?

              <Box sx={{ flexGrow: 0 }}>
                {isBeta && <Tooltip title="Beta Mode Switch">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={betaToggle}
                        name='betaToggle'
                        onChange={handleBetaToggle}
                        color="warning"
                        sx={{ mr: 1 }}
                      />
                    }
                    label="Beta Mode"
                    labelPlacement="start"
                  />
                </Tooltip>}
                <Tooltip title="Dark mode switch">
                  <ThemeToggleButton color='alwaysWhite' />
                </Tooltip>
                <Tooltip title="Open display options">
                  <IconButton
                    size="large"
                    aria-label="Invigilation display options"
                    aria-controls="invigilation-options-menu"
                    aria-haspopup="false"
                    onClick={() => handleSetShowSettingsPanel()}
                    color="inherit"
                  >
                    <DisplaySettings />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Help">
                  <IconButton
                    size="large"
                    aria-label="Help"
                    color="inherit"
                    component={Link}
                    href="https://docs.examdisplay.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <Help />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open settings">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="account" onClick={handleCloseUserMenu}>
                    <Settings sx={{ mr: 1 }} />
                    <Link style={{ textDecoration: "none", color: "text.disabled" }} component={RouterLink} to={`/account`}>Settings</Link>
                  </MenuItem>

                  <MenuItem key="subscription" onClick={handleCloseUserMenu}>
                    <Paid sx={{ mr: 1 }} />
                    <Link style={{ textDecoration: "none", color: "text.disabled" }} href="https://billing.stripe.com/p/login/4gwaHsfdl78sedi288">Subscription</Link>
                  </MenuItem>

                  <MenuItem key="signout" onClick={signOut}>
                    <Logout sx={{ mr: 1 }} />
                    <Link style={{ textDecoration: "none", color: "text.disabled" }} component={RouterLink} to={`/`}>Sign Out</Link>
                  </MenuItem>
                </Menu>
              </Box>
              /* If not, show sign up and sign in buttons */
              :
              <>

                {isBeta && <ThemeToggleButton color='alwaysWhite' />}

                <Button
                  variant='contained'
                  key="signIn"
                  color='tertiary'
                  startIcon={<Login size='large' />}
                  sx={{ my: 2, color: 'white', outline: 'white' }}
                >
                  <Link style={{ textDecoration: "none", color: "white" }} component={RouterLink} to={`/signin`}>
                    Sign in
                  </Link>
                </Button>
              </>
            }
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
};
export default NavBar;
